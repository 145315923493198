import { Grid, Typography, CircularProgress} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from 'react-admin';
import { uploadFile } from "../../api/FileAPI";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "1rem"
    
  },
  hidden: {
    display: "none"
  }
}));

const UploadSubscription = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const onClose = props?.onClose;
  const SUBSCRIPTION_UPLOAD = "go-zero-subscription-upload"
  const GO_ZERO_FILE_KEY = "subscription-templates-go-zero"

  const openInNewTab = (key) => {
    const win = window.open(`/api/file/${key}`, "_blank");
    win.focus();
    // getFile(key)
    //   .then(blob => {
    //     const file = window.URL.createObjectURL(blob);
    //     const win = window.open(file, "_blank");
    //     win.focus();
    //   }); 
  }

  const handleUpload = (event) => {
    setLoading(true)
    uploadFile(event.target.files[0], "", SUBSCRIPTION_UPLOAD)
      .then((response) => {
        if (response?.success){
          setLoading(false)     
          window.location.reload()          
        } else{
          setLoading(false)    
          onClose()    
          alert(`Error creating subscription: ${response.error}`)
        }         
      })
      .catch((error) => {
        setLoading(false);
        onClose()
        alert("Error occurred uploading subscription.")                
      });
  }

  return (
    <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Supported Subscriptions:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">EAL - Go Zero</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button 
              component="span"
              onClick={() => openInNewTab(GO_ZERO_FILE_KEY)} 
              variant="text"
              label="Go Zero Sample Template File" 
              color="primary">          
            </Button>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button 
              className={classes.button}
              component="span"
              onClick={onClose} 
              variant="outlined"
              label="Close" 
              color="primary">          
            </Button>
            <div>
              <input 
                className={classes.hidden}
                id="subUpload"
                type="file" 
                accept=".xlsx" 
                name="subUploadInput" 
                label="Select A File" 
                onChange={(e) => { handleUpload(e); }} 
              />
              <label htmlFor="subUpload">
                <Button
                  className={classes.button}
                  component="span"
                  variant="contained"
                  color="primary"
                  startIcon={loading ? <CircularProgress color="inherit" size={'1rem'}/> : <PublishIcon />}
                  label="Upload"
                />
              </label>
            </div>
          </Grid>
        </Grid>
    </div>
  )
};
export default UploadSubscription;